import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql }  from 'gatsby';

import Layout from '../components/layout';
import { PageTitle } from '../components/title';
import { GridRow } from '../components/widgets/grid';

import Social  from '../pages/widgets/social';

export default function Template({
  data
}) {

  const { usecase, site: { siteMetadata } } = data;
  const { topic, description, bullets, path } = usecase;

  const { siteUrl } = siteMetadata;
  const shareUrl = siteUrl + '/' + path;

  return (
    <Layout>
      <div className="">

        <Helmet>
          <title>{topic} | SwitchSmith</title>
          <link rel="canonical" href={shareUrl}/>
        </Helmet>

        <PageTitle title={topic}/>


        <GridRow>
          <div className="row pt3 pb5">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

              <p>{description}</p>

              <div>
                <ul>
                  {bullets.map((b, idx) => <li key={idx}>{b}</li>)}
                </ul>
              </div>

            </div>
          </div>
        </GridRow>

      </div>
    </Layout>
  );
}


export const query = graphql`
  query UseCaseById($id: String!) {

    site {
      siteMetadata {
        siteUrl
        site_url: siteUrl
      }
    }

    usecase(id: { eq: $id } ) {
      id
      path
      topic
      description
      bullets
    }
  }
`
;
